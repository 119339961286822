import { LOCAL_STORAGE_RECENT_ENTITIES } from '../config/app';

export const getRecentEntities = (type) => {
  let recentsEntities = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_RECENT_ENTITIES}:${type}`));

  if (!recentsEntities) {
    recentsEntities = [];
  }

  return recentsEntities;
};

export const storeRecentEntities = (entity, type) => {
  const recentsVcubs = getRecentEntities(type);
  const stationExists = recentsVcubs.find(item => item.id === entity.id);

  if (typeof stationExists === 'undefined') {
    entity.isRecentSearch = true;

    if (recentsVcubs.length > 5) {
      recentsVcubs.pop();
    }

    recentsVcubs.unshift(entity);
    localStorage.setItem(`${LOCAL_STORAGE_RECENT_ENTITIES}:${type}`, JSON.stringify(recentsVcubs));
  }

  return recentsVcubs;
};
