import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';

import SchedulesSearchBlock from '../blocks/schedules/SchedulesSearchBlock';
import RoutesSearchBlock from '../blocks/routes/RoutesSearchBlock';
import HomepagePrimarySectionBlockTabs from '../blocks/homepage/HomepagePrimarySectionBlockTabs';

// 2022_HP_RECAST
const PrimarySectionRecast = (props) => {
  const { bg, isMobile } = props;
  const [activeBg, setActiveBg] = useState(null);
  const sectionStyle = {
    // eslint-disable-next-line no-nested-ternary
    backgroundImage: `url(${(isMobile && bg && 'mobile' in bg) ? bg.mobile : (bg && 'mobile' in bg) ? bg.desktop : ''}`,
  };

  useEffect(() => {
    if (isMobile && bg && 'mobile' in bg) {
      setActiveBg(bg.mobile);
    }
  }, [bg]);

  if (isMobile) {
    return (
      <div className="bg-tram tabs">
        <div id="bg-tram" className="tbm-section tabs" />
        <div className="container">
          <div className="row align-start">
            {/* eslint-disable-next-line react/no-danger */}
            <h1 className="col-sm-12 text-white" dangerouslySetInnerHTML={{ __html: Translate('others.title_home') }} />
            <HomepagePrimarySectionBlockTabs isMobile />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-tram tabs">
      <div id="bg-tram" className="tbm-section" style={sectionStyle} />
      <div className="container">
        <div className="row align-start justify-content-center">
          {/* eslint-disable-next-line react/no-danger */}
          <h1 className="col-lg-4 text-white" dangerouslySetInnerHTML={{ __html: Translate('others.title_home') }} />
          <div className="col-sm-12 col-md-6 col-lg-3 tab">
            <h2 className="col-sm-12 tab-header">{Translate('homepage.trip.schedule.title')}</h2>
            <SchedulesSearchBlock displayTitle={!isMobile} />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 tab routes">
            <h2 className="col-sm-12 tab-header">{Translate('route.title')}</h2>
            <RoutesSearchBlock />
          </div>
        </div>
      </div>
    </div>
  );
};

PrimarySectionRecast.propTypes = {
  bg: PropTypes.shape({
    desktop: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
  }),
  isMobile: PropTypes.bool.isRequired,
};

PrimarySectionRecast.defaultProps = {
  bg: null,
};

const mapStateToProps = state => ({
  bg: state.home.bg,
  isMobile: state.app.isMobile,
});


export default connect(mapStateToProps, null)(PrimarySectionRecast);
