import React, { useEffect } from 'react';
import Head from 'next/head';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import OtherSection from '../components/section/OtherSection';
import TrafficSection from '../components/section/TrafficSection';
import LastNewsSection from '../components/section/LastNewsSection';
import { fetchBackground } from '../actions/HomepageActions';
import { fetchStickyNews, fetchLatestNews } from '../actions/NewsActions';
import { fetchPreviewTrafficInfo } from '../actions/TrafficInfoActions';
import { fetchDrupalLinks, fetchDrupalTermsLinks } from '../actions/AppActions';
import PrimarySectionRecast from '../components/section/PrimarySectionRecast';
import { syncStoreFromRequest } from '../actions/RouteActions';
import shapes from '../config/shapes';
import PlanSection from '../components/section/PlanSection';
import AirQualitySection from '../components/section/AirQualitySection';

const Homepage = (props) => {
  const { isServer, query } = props;
  const { isMobile } = useSelector(state => state.app);

  useEffect(() => {
    if (!isServer) {
      props.fetchStickyNews();
      props.fetchLatestNews();
      props.fetchBackground();
      props.fetchDrupalLinks();
      props.fetchDrupalTermsLinks();
    }
    // Because page cache.
    props.fetchPreviewTrafficInfo();
    if (query) {
      props.syncStoreFromRequest(query, true);
    }
  }, []);

  return (
    <div className="homepage">
      <Head>
        <title>{Translate('app.home_title')}</title>
        <meta name="description" content={Translate('app.meta_desc')} />
      </Head>

      <PrimarySectionRecast />
      <TrafficSection />
      <PlanSection />
      <LastNewsSection />
      {isMobile && <AirQualitySection />}
      <OtherSection />
    </div>
  );
};

Homepage.getInitialProps = async ({ reduxStore, query, req }) => {
  const isServer = !!req;

  if (isServer) {
    await reduxStore.dispatch(fetchPreviewTrafficInfo());
    await reduxStore.dispatch(fetchStickyNews());
    await reduxStore.dispatch(fetchLatestNews());
    await reduxStore.dispatch(fetchBackground());
    await reduxStore.dispatch(fetchDrupalLinks());
    await reduxStore.dispatch(fetchDrupalTermsLinks());
  }

  return { isServer, query };
};

Homepage.propTypes = {
  query: PropTypes.shape(shapes.routeQuery).isRequired,
  isServer: PropTypes.bool.isRequired,
  fetchPreviewTrafficInfo: PropTypes.func.isRequired,
  fetchStickyNews: PropTypes.func.isRequired,
  fetchLatestNews: PropTypes.func.isRequired,
  fetchBackground: PropTypes.func.isRequired,
  fetchDrupalLinks: PropTypes.func.isRequired,
  fetchDrupalTermsLinks: PropTypes.func.isRequired,
  syncStoreFromRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPreviewTrafficInfo,
  fetchStickyNews,
  fetchLatestNews,
  fetchBackground,
  fetchDrupalLinks,
  fetchDrupalTermsLinks,
  syncStoreFromRequest,
}, dispatch);

export default connect(null, mapDispatchToProps)(Homepage);
