import { checkIsWebview } from '../../helpers/GenericHelper';

export const taggingEventOtherElement = (tbmClickItem) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: tbmClickItem,
      };
      window.dataLayer.push({
        event: 'tbm_click_home_other_topics',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  }

  return null;
};
