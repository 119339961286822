import React from 'react';
import PropTypes from 'prop-types';
import routes from '../../../routes';
import Link from '../Link';
import { LINES_TYPES } from '../../../config/line';
import { taggingEventSchedules } from '../../../tagging/schedules/SchedulesTaggingActions';

export const renderPicto = line => (
  <div className="tbm-icon-picto">
    <img src={line.picto} alt={line.code} />
  </div>
);

export const renderLine = (line, size) => {
  if (line.type === LINES_TYPES.BATCUB) {
    return (
      <div
        className="tbm-icon"
        style={{
          backgroundColor: `#${line.bgColor}`,
          color: `#${line.textColor}`,
          height: `${size}px`,
          lineHeight: `${size}px`,
        }}
      >
        <span>{line.code}</span>
      </div>
    );
  }

  return (
    <div
      className={`tbm-icon ${line.type === LINES_TYPES.TRAM ? 'circle' : ''} ${line.type === LINES_TYPES.TRAIN_TER ? 'train' : ''} ${line.type === LINES_TYPES.AUTOCAR ? 'autocar' : ''}`}
      style={{
        backgroundColor: `#${line.bgColor}`,
        color: `#${line.textColor}`,
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
      }}
    >
      <span>{line.code}</span>
    </div>
  );
};

const ScheduleLinePicto = (props) => {
  const { line, stopArea, size, tagging } = props;
  return (
    <div className="m-b-0-25">
      <Link
        route={routes.SCHEDULE.RESULTS.name}
        params={{
          line: line.externalCode,
          stop_area: stopArea || undefined,
        }}
        key={line.id}
      >
        <a className="l-tbm" onClick={() => (tagging ? taggingEventSchedules({ item: tagging, timetablesItem: line.name.substr(0, 99) }) : null)}>
          {
            line.picto
              ? renderPicto(line, size)
              : renderLine(line, size)
          }
        </a>
      </Link>
    </div>
  );
};

ScheduleLinePicto.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string.isRequired,
    picto: PropTypes.string,
    type: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    externalCode: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
  stopArea: PropTypes.string,
  size: PropTypes.number,
  tagging: PropTypes.string,
};

ScheduleLinePicto.defaultProps = {
  stopArea: null,
  size: 30,
  tagging: null,
};

export default ScheduleLinePicto;
