import React from 'react';
import PropTypes from 'prop-types';

import { HOME_PRIMARY_SECTION_BLOCK } from "../../../config/home";
import HomepagePrimarySectionTab from "./HomepagePrimarySectionTab";

const SchedulesJourneysTabs = (props) => {
    const { activeMode, handleChangeTab } = props;

    return (
        <div className="tab-wrapper">
            <HomepagePrimarySectionTab
                mode={HOME_PRIMARY_SECTION_BLOCK.BLOCK_SCHEDULES}
                label="schedules.title"
                isActive={activeMode === HOME_PRIMARY_SECTION_BLOCK.BLOCK_SCHEDULES}
                handleChangeTab={handleChangeTab}
            />
            <HomepagePrimarySectionTab
                mode={HOME_PRIMARY_SECTION_BLOCK.BLOCK_JOURNEYS}
                label="route.title"
                isActive={activeMode === HOME_PRIMARY_SECTION_BLOCK.BLOCK_JOURNEYS}
                handleChangeTab={handleChangeTab}
            />
        </div>
    );
};

SchedulesJourneysTabs.propTypes = {
    activeMode: PropTypes.string.isRequired,
    handleChangeTab: PropTypes.func.isRequired,
};

export default SchedulesJourneysTabs;
