import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SubtitleIcon = (props) => {
  const {
    text, iconName, size, isMobile, additionalClassName, svgClassName, textClassName,
  } = props;

  let iconSize = size;
  if (isMobile) {
    iconSize = size * 2 / 3;
  }

  return (
    <h2 className={`title-icon m-b ${additionalClassName !== null ? additionalClassName : ''}`}>
      <svg width={iconSize} height={iconSize} className={svgClassName}>
        <use xlinkHref={`#${iconName}`} />
      </svg>
      <span className={textClassName}>{Translate(text)}</span>
    </h2>
  );
};

SubtitleIcon.propTypes = {
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  size: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
  additionalClassName: PropTypes.string,
  svgClassName: PropTypes.string,
  textClassName: PropTypes.string,
};

SubtitleIcon.defaultProps = {
  size: 36,
  additionalClassName: null,
  svgClassName: 'f-blue',
  textClassName: '',
};


const mapStateToProps = state => ({
  isMobile: state.app.isMobile,
});

export default connect(mapStateToProps, null)(SubtitleIcon);
