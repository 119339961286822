import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import SubtitleIcon from '../../../components/elements/title/SubtitleIcon';

const HomepagePrimarySectionTab = (props) => {
    const {
        mode, label, isActive, handleChangeTab,
    } = props;
    const { isMobile } = useSelector(state => state.app);
    const [isClicked, setIsClicked] = useState(false);

    const onClickTab = (m) => {
        handleChangeTab(m);
        setIsClicked(true);
    };

    return (
        <div
            className={`tab hp-tab ${isActive ? 'active' : ''} ${isClicked ? 'tab-clicked' : ''}`}
            onClick={() => onClickTab(mode)}
            onBlur={() => setIsClicked(false)}
            onKeyPress={() => handleChangeTab(mode)}
            role="button"
            tabIndex="0"
        >

            <h2 className="tab-header"><span>{Translate(label).toUpperCase()}</span></h2>
        </div>
    );
};

HomepagePrimarySectionTab.propTypes = {
    mode: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    handleChangeTab: PropTypes.func.isRequired,
};

HomepagePrimarySectionTab.defaultProps = {
    isActive: false,
};

export default HomepagePrimarySectionTab;
