import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { useSelector } from 'react-redux';
import routes from '../../routes';
import Link from '../elements/Link';

const PlanSection = () => {
  const { isMobile } = useSelector(state => state.app);

  if (isMobile) {
    return (
      <div className="tbm-section bg-theme-primary">
        <div className="container">
          <div className="row align-content-stretch align-items-center">
            <div className="col-xs-2 col-lg-offset-1 col-lg-6 p-md-m-0 p-md-r-0">
              <h2 className="text-white m-b m-md-b-1-5 ws-nw h1">
                {Translate('plan.title_mobile') }
              </h2>
            </div>

            <div className="p-l-0-75 p-r-0-75 plans-home-container">
              <img
                src="/themes/custom/infotbm/images/home/plan_home_mobile.png"
                className="plans-home-img"
                alt="plan_dynamique"
              />
              <Link route={routes.PLAN.DYNAMIC.name}>
                <a className="btn plans-home-btn">{Translate('plan.show')}</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tbm-section bg-theme-primary">
      <div className="container">
        <div className="row align-content-stretch align-items-center">
          <div className="col-xs-2 col-lg-offset-1 col-lg-6 p-md-m-0 p-md-r-0">
            <div className="plans-home-container">
              <img
                src="/themes/custom/infotbm/images/home/plan_home.png"
                className="plans-home-img"
                alt="plan_dynamique"
              />
              <Link route={routes.PLAN.DYNAMIC.name}>
                <a className="btn plans-home-btn">{Translate('plan.show')}</a>
              </Link>
            </div>
          </div>

          <div className="col-xs-12 col-lg-offset-1 col-lg-4 display-none display-md-block">
            <h2 className="text-white m-b-2 m-md-b-1-5 ws-nw h1">{Translate('plan.title')}</h2>
            <div className="section-desc text-white display-none display-md-block">
              {/* eslint-disable react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: Translate('plan.text_1') }} />
              <span dangerouslySetInnerHTML={{ __html: Translate('plan.text_2') }} />
              <span dangerouslySetInnerHTML={{ __html: Translate('plan.text_3') }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanSection;
