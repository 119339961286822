import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import routes from '../../../routes';
import Link from '../Link';
import LinePicto from '../lines/LinePicto';
import { SET_POPIN_ALERTS_V2 } from '../../../actions/TrafficInfoActions';
import { hasRealAlerts } from '../../../helpers/TrafficHelper';
import { taggingEventAlert } from '../../../tagging/alert/AlertTaggingActions';

/**
 * Need to have an array line.alerts in each line.
 * See @TrafficHelper.mapAlertsToLines()
 */
const LinePictoTraffic = ({ line, checkAlertCause }) => {
  const dispatch = useDispatch();

  if (!('alerts' in line)
    || line.alerts.length <= 0
    || (checkAlertCause && !hasRealAlerts(line.alerts))
  ) {
    return (
      <>
        <a data-for={`${line.code}_tooltip`} data-tip>
          <LinePicto line={line} />
          <ReactTooltip
            id={`${line.code}_tooltip`}
            place="bottom"
            effect="solid"
            type="light"
            border
            borderColor="#737373"
            className="alerts-tooltip"
          >
            <p className="text-green fs-normal m-b-0" style={{ maxWidth: '150px', textAlign: 'center' }}>{ Translate('traffic.no_disruption_on_this_line') }</p>
          </ReactTooltip>
        </a>
      </>
    );
  }

  if (line.alerts.length === 1) {
    return (
      <span onClick={() => taggingEventAlert(line?.name)}>
        <Link route={routes.TRAFFIC.DETAILS.name} params={{ id: line.alerts[0].id }}>
          <a className="l-tbm">
            <LinePicto line={line} />
          </a>
        </Link>
      </span>
    );
  }

  if (line.alerts.length > 1) {
    return (
      <span
        onClick={() => dispatch({ type: SET_POPIN_ALERTS_V2, payload: { alerts: line.alerts, line } })}
        onKeyPress={() => dispatch({ type: SET_POPIN_ALERTS_V2, payload: { alerts: line.alerts, line } })}
        role="link"
        tabIndex={0}
      >
        <LinePicto line={line} />
      </span>
    );
  }

  return null;
};

LinePictoTraffic.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string.isRequired,
    picto: PropTypes.string,
    code: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    impact: PropTypes.bool,
  }).isRequired,
  checkAlertCause: PropTypes.bool,
};

LinePictoTraffic.defaultProps = {
  checkAlertCause: false,
};

export default LinePictoTraffic;
