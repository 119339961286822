import React from 'react';
import PropTypes from 'prop-types';
import shapes from '../../../config/shapes';
import { LINES_TYPES } from '../../../config/line';

const LinePicto = (props) => {
  const { line, size } = props;

  if (!line) {
    return null;
  }

  const bgColor = 'bgColor' in line && line.bgColor ? line.bgColor : line.color;

  const renderTransportType = () => {
    const pictoStyle = {
      backgroundColor: `#${bgColor}`,
      color: `#${line.textColor}`,
      width: `${size}px`,
      height: `${size}px`,
      lineHeight: `${size}px`,
    };

    switch (line.type) {
      case LINES_TYPES.BUS:
      case LINES_TYPES.BATCUB:
        return (
          <div
            className="tbm-icon"
            style={pictoStyle}
          >
            <span>{line.code}</span>
          </div>
        );
      case LINES_TYPES.TRAIN_TER:
        return (
          <div
            className="tbm-icon train"
            style={pictoStyle}
          >
            <span>{line.code}</span>
          </div>
        );
      case LINES_TYPES.AUTOCAR:
        return (
          <div
            className="tbm-icon autocar"
            style={pictoStyle}
          >
            <span>{line.code}</span>
          </div>
        );
      default:
        return (
          <div
            className="tbm-icon circle"
            style={pictoStyle}
          >
            <span>{line.code}</span>
          </div>
        );
    }
  };

  return (
    <div className="m-b-0-25">
      {
        line.picto
          ? (
            <div className="tbm-icon-picto">
              <img src={line.picto} alt={line.code} style={{ height: size }} />
            </div>
          )
          : renderTransportType()
      }
    </div>
  );
};

LinePicto.propTypes = {
  line: PropTypes.shape(shapes.line).isRequired,
  size: PropTypes.number,
};

LinePicto.defaultProps = {
  size: 30,
};

export default LinePicto;
