import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchDrupalLinks } from '../../actions/AppActions';

/**
 * /!\ By default, this component fetch Drupal links,
 * but if you want your link is SSR,
 * fetch drupalLinks on the getInitialProps() method of the concerned page.
 */
const LinkToDrupal = (props) => {
  const { linkKey, text, classes, taggingEventOnClick } = props;
  const drupalLinks = useSelector(state => state.app.drupalLinks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDrupalLinks());
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={drupalLinks && linkKey in drupalLinks && drupalLinks[linkKey] ? drupalLinks[linkKey].link : '#'}
      type="button"
      className={classes}
      onClick={taggingEventOnClick || null}
    >
      { text }
    </a>
  );
};

LinkToDrupal.propTypes = {
  linkKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  classes: PropTypes.string,
  taggingEventOnClick: PropTypes.func,
};

LinkToDrupal.defaultProps = {
  classes: '',
  taggingEventOnClick: null,
};

export default LinkToDrupal;
