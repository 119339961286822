import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import Link from './Link';

const ButtonLogo = (props) => {
  const {
    route, iconName, textKey, externalLink, classWrapper, params, pictoClasses, taggingEventOnClick, logoWidth, logoHeight, newTab
  } = props;

  const renderExternalLinkContent = () => (
    <a href={route} target={`${!newTab ? '_self' : '_blank _blank'}`} className="b-link b-link-arrow b-link-large" onClick={taggingEventOnClick || null}>
      <svg width={(logoWidth) ? logoWidth : "45"} height={(logoHeight) ? logoHeight : "45"} className={pictoClasses}>
        <use xlinkHref={`#${iconName}`} />
      </svg>
      <span className="fs-md-h4 font-bold text-left w-70 p-r-2">{Translate(textKey)}</span>
    </a>
  );

  const renderLinkContent = () => (
    <Link route={route} params={params}>
      <a className="b-link b-link-arrow b-link-large" onClick={taggingEventOnClick || null}>
        <svg width={(logoWidth) ? logoWidth : "45"} height={(logoHeight) ? logoHeight : "45"} className={pictoClasses}>
          <use xlinkHref={`#${iconName}`} />
        </svg>
        <span className="fs-md-h4 font-bold text-left w-70 p-r-2">{Translate(textKey)}</span>
      </a>
    </Link>
  );

  if (externalLink) {
    return (
      <div className={classWrapper}>
        {renderExternalLinkContent()}
      </div>
    );
  }

  return (
    <div className={classWrapper}>
      {renderLinkContent()}
    </div>
  );
};

ButtonLogo.propTypes = {
  externalLink: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  classWrapper: PropTypes.string,
  params: PropTypes.object,
  pictoClasses: PropTypes.string,
  taggingEventOnClick: PropTypes.func,
  logoWidth: PropTypes.string,
  logoHeight: PropTypes.string,
};

ButtonLogo.defaultProps = {
  externalLink: false,
  classWrapper: 'col-xs-12 col-md-6',
  params: {},
  pictoClasses: '',
  taggingEventOnClick: null,
};

export default ButtonLogo;
