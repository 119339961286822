import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initFavorites, resetFavoriteLoading } from '../../actions/FavoritesActions';

const useFavoriteInitialization = () => {
  const { headers, isWebview } = useSelector(state => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFavorites(isWebview, headers));

    return () => dispatch(resetFavoriteLoading());
  }, []);
};

export default useFavoriteInitialization;
