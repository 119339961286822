import React, { Fragment } from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import TrafficPreview from '../blocks/traffic/TrafficPreview';

const TrafficSection = () => {
  const { isMobile } = useSelector(state => state.app);

  return (
    <div className={`tbm-section custom-pt traffic-section ${isMobile && 'bg-blue'}`}>
      <div className="container">
        <div className="row align-content-stretch align-items-center">
          <div className="col-xs-12 col-lg-offset-1 col-lg-3">
            {isMobile ? (
              <h2 className="text-white m-b m-md-b-1-5 ws-nw h1">
                {Translate('traffic.title_mobile', { time: moment().format('H[h]mm') }) }
              </h2>
            ) : (
              <Fragment>
                <h2 className="title-icon m-b m-md-b-1-5 ws-nw h1">
                  <svg width="40" height="40">
                    <use xlinkHref="#icon-alert-blur-pink" />
                  </svg>
                  <span>{Translate('traffic.title')}</span>
                </h2>
                <div className="section-desc text-light display-none display-md-block">
                  {Translate('traffic.home')}
                </div>
              </Fragment>
            )}

            <div className="section-img text-align-center display-none display-md-block">
              <img src="/themes/custom/infotbm/images/home/infotraffic.jpg" alt={Translate('traffic.title')} />
            </div>
          </div>

          <div className="col-xs-12 col-lg-offset-1 col-lg-6">
            <TrafficPreview showTitle={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrafficSection;
