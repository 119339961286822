import { checkIsWebview } from '../../helpers/GenericHelper';

export const taggingEventNews = (tbmClickItem, itemNumber = 0) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: tbmClickItem,
        tbm_position: itemNumber,
      };
      window.dataLayer.push({
        event: 'tbm_click_home_news',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  }

  return null;
};
