import { TRAFFIC_ALERT_LINE_URL } from '../config/config';
import { fetchWithTimeout } from './RequestHelper';
import { ALERT_CAUSE, FAVORITES_ALERTS_SORT_ORDER } from '../config/traffic';

export const mapAlertsToLines = (lines, alerts) => {
  lines.forEach((line) => {
    line.alerts = [];
  });

  alerts.forEach((alert) => {
    alert.impacts.forEach((impact) => {
      if (impact.line !== null) {
        const line = lines.find(l => l.id === impact.line.id);

        if (line) {
          line.alerts.push(alert);
        }
      }
    });
  });

  lines = lines.sort((a, b) => FAVORITES_ALERTS_SORT_ORDER.indexOf(a.type) - FAVORITES_ALERTS_SORT_ORDER.indexOf(b.type));

  return lines;
};

/**
 * Exclude alerts with 'Information' cause.
 */
export const hasRealAlerts = alerts => (
  alerts.find(alert => alert.cause !== ALERT_CAUSE.INFORMATION) !== undefined
);

export const lineHasAlert = async (lineId) => {
  try {
    const alertQuery = await fetchWithTimeout(`${TRAFFIC_ALERT_LINE_URL}/${lineId}`, 5000);
    const alerts = await alertQuery.json();

    return alerts.length > 0 && hasRealAlerts(alerts);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Une erreur est survenue lors de la récupération de salertes de la ligne ${lineId} : ${e}`);
  }
};
