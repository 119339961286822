import React from 'react';
import { connect, useSelector } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import NewsItem from '../blocks/news/NewsItem';
import LinkToDrupal from '../elements/LinkToDrupal';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { taggingEventNews } from '../../tagging/homepage/NewsTaggingActions';

const LastNewsSection = (props) => {
  const { news } = props;
  const { isMobile, isSmMobile } = useSelector(state => state.app);

  if (news) {
    return (
      <div className={`tbm-section ${isMobile ? '' : 'section-news'}`}>
        <div className="container">
          <div className="row align-content-stretch align-items-start">
            <div className="col-xs-12 col-lg-offset-1 col-lg-5">
              <h2 className="title-icon m-b m-md-b-1-5 ws-nw h1">
                <svg width="40" height="40" className="f-blue">
                  <use xlinkHref="#icon-news" />
                </svg>
                <span>{Translate('news.title')}</span>
              </h2>
              <div className="display-none display-md-block">
                <div className="section-desc text-light">
                  {Translate('news.home')}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-lg-offset-1 col-lg-10">
              <div className="block-news homepage-news row">
                {
                  isSmMobile ? (
                    <Carousel showArrows={false} showThumbs={false} swipeScrollTolerance={30} preventMovementUntilSwipeScrollTolerance centerMode centerSlidePercentage={80}>
                      {news.map(n => (
                        <NewsItem key={n.nid} news={n} newsIndex={news.indexOf(n)} />
                      ))}
                    </Carousel>
                  ) : news.map(n => (
                    <NewsItem key={n.nid} news={n} size="1/4" newsIndex={news.indexOf(n)} />
                  ))
                }

                <div className="text-center p-t-1 p-md-t-2 col-xs-12 m-b m-md-b-0">
                  <LinkToDrupal linkKey="news" text={Translate('news.more')} classes="btn btn-blue" taggingEventOnClick={() => taggingEventNews('See all actus')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

LastNewsSection.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      nid: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

const mapStateToProps = state => ({
  news: state.news.latest,
});

export default connect(mapStateToProps, null)(LastNewsSection);
