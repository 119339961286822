import React, { Fragment } from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import { useSelector } from 'react-redux';

const AirQualitySection = () => {
  const { isSmMobile } = useSelector(state => state.app);

  return (
    <Fragment>
      <div className="container">
        <h3 className="m-b m-md-b-1-5 ws-nw h3">{Translate('others.air_quality')}</h3>
        <div style={{ height: isSmMobile ? '470px' : '420px' }}>
          <iframe
            title="widget"
            src="https://www.atmo-nouvelleaquitaine.org/widget-mon-air/widget/commune/33063"
            style={{
              width: '100%',
              height: '100%',
              border: 0,
              borderRadius: '20px',
            }}
          />
        </div>
        <div className="m-t-1-5">
          <p>
            {Translate('others.air_quality_infos')}
            {' '}
            <a
              className="air-quality-link"
              href="https://www.atmo-nouvelleaquitaine.org/air-commune/Bordeaux/33063/indice-atmo?adresse=Boulevard%20du%20Pr%C3%A9sident%20Wilson%2033000%20Bordeaux"
            >
              {Translate('others.air_quality_website')}
            </a>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default AirQualitySection;
