import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HOME_PRIMARY_SECTION_BLOCK } from '../../../config/home';
import SchedulesJourneysTabs from './SchedulesJourneysTabs';
import SchedulesSearchBlock from '../schedules/SchedulesSearchBlock';
import RoutesSearchBlock from '../routes/RoutesSearchBlock';

const renderHomepagePrimarySection = (activeMode, isMobile) => {
  switch (activeMode) {
    case HOME_PRIMARY_SECTION_BLOCK.BLOCK_SCHEDULES:
      return <SchedulesSearchBlock displayTitle={!isMobile} />;
    case HOME_PRIMARY_SECTION_BLOCK.BLOCK_JOURNEYS:
      return <RoutesSearchBlock />;
    default:
      return null;
  }
};

const HomepagePrimarySectionBlockTabs = (props) => {
  const {
    mode,
    isMobile,
  } = props;
  const [activeMode, setActiveMode] = useState(mode);
  const { departure, arrival } = useSelector(state => state.routes);

  useEffect(() => {
    if (isMobile && departure && arrival) {
      setActiveMode(HOME_PRIMARY_SECTION_BLOCK.BLOCK_JOURNEYS);
    }
  }, []);

  return (
    <div className="m-b tabs-container" id="primary-section-container">
      <SchedulesJourneysTabs activeMode={activeMode} handleChangeTab={newMode => setActiveMode(newMode)} />
      {renderHomepagePrimarySection(activeMode, isMobile)}
    </div>
  );
};

HomepagePrimarySectionBlockTabs.propTypes = {
  mode: PropTypes.string,
  isMobile: PropTypes.bool,
};

HomepagePrimarySectionBlockTabs.defaultProps = {
  mode: HOME_PRIMARY_SECTION_BLOCK.BLOCK_SCHEDULES,
  isMobile: false,
};

export default HomepagePrimarySectionBlockTabs;
