import { checkIsWebview } from '../../helpers/GenericHelper';

export const taggingEventSchedules = (tbmClick) => {
  if (!checkIsWebview()) {
    try {
      const params = {
        tbm_click_item: tbmClick.item,
        tbm_click_timetables_item: tbmClick.timetablesItem,
      };
      window.dataLayer.push({
        event: 'tbm_click_timetables',
        ...params,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('dataLayer', e);
    }
  }
  return null;
};
