import React from 'react';
import PropTypes from 'prop-types';
import { taggingEventNews } from '../../../tagging/homepage/NewsTaggingActions';

const NewsItem = (props) => {
  const { news, size, newsIndex } = props;

  let customClass = 'news-item';

  switch (size) {
    case '1/4':
      customClass += ' col-md-3';
      break;
    case '1/3':
      customClass += 'col-md-4';
      break;
    default:
      customClass += ' col-md-3';
      break;
  }

  return (
    <div className={customClass}>
      <a href={news.link} title={news.title} className="b-link-item" onClick={() => taggingEventNews(news.title.substr(0, 99), newsIndex + 1)}>
        <div className="border-top-radius img-wrapper">
          <picture className="m-b-0">
            <source media="(max-width: 767px)" srcSet={news.image} />
            <img src={news.image} alt={news.title} />
          </picture>
        </div>
        <span className="b-link-item-title">{news.title}</span>
      </a>
    </div>
  );

};

NewsItem.defaultPropTypes = {
  size: 'half',
};

NewsItem.propTypes = {
  news: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    image_thumb: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.string,
};

export default NewsItem;
