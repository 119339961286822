import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import routes from '../../routes/index';
import ButtonLogo from '../elements/ButtonLogo';
import { taggingEventOtherElement } from '../../tagging/homepage/OtherSectionTaggingActions';

const OtherSection = (props) => {
  const { drupalLinks } = props;
  const { isMobile } = useSelector(state => state.app);
  const batcub = useSelector(state => state.lines.batcub);
  const batcubParams = batcub.length > 0 ? { line: batcub[0].externalCode } : {};

  return (
    <div className="tbm-section bg-theme-primary">
      <div className="container">
        <div className="row align-content-stretch align-items-center">
          <div className="col-xs-12 col-lg-offset-1 col-lg-4 display-none display-md-block">
            <div style={{ height: '416px' }}>
              <iframe
                title="widget"
                src="https://www.atmo-nouvelleaquitaine.org/widget-mon-air/widget/commune/33063"
                style={{
                  width: '100%',
                  height: '100%',
                  border: 0,
                  borderRadius: '20px',
                }}
              />
            </div>

            <div className="m-t-1-5 text-center">
              <p className="text-white font-bold">
                {Translate('others.air_quality_infos')}
                {' '}
                <a
                  className="air-quality-link text-white font-bold"
                  style={{ textDecoration: 'underline' }}
                  href="https://www.atmo-nouvelleaquitaine.org/air-commune/Bordeaux/33063/indice-atmo?adresse=Boulevard%20du%20Pr%C3%A9sident%20Wilson%2033000%20Bordeaux"
                >
                  {Translate('others.air_quality_website')}
                </a>
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-lg-offset-1 col-lg-6 p-md-m-0 p-md-r-0">
            <div className="row justify-space-between align-content-stretch align-item-center">
              <div className="col-xs-12 col-md-6 align-self-center">
                <h2 className={`text-white h1 text-${isMobile ? 'left m-b-2 m-t-1-5 ' : 'center m-b-1-5'}`}>
                  {Translate(`others.title${isMobile ? '_mobile' : ''}`)}
                </h2>
              </div>
              <ButtonLogo route={routes.PARKING.HOME.name} iconName="icon-pr-shadow" textKey="parking.title" taggingEventOnClick={() => taggingEventOtherElement(Translate('parking.title'))} />
              {/* eslint-disable-next-line max-len */}
              <ButtonLogo route={routes.LINE.DETAILS.name} params={batcubParams} iconName="icon-batcub-shadow" textKey="batcub.long_title" taggingEventOnClick={() => taggingEventOtherElement(Translate('batcub.long_title'))} />
              {
                drupalLinks.phmr
                // eslint-disable-next-line max-len
                  && <ButtonLogo route={drupalLinks.phmr.link} iconName="icon-phmr-shadow" textKey="others.home_accessibility_mobibus" externalLink taggingEventOnClick={() => taggingEventOtherElement(Translate('others.home_accessibility_mobibus'))} />
              }
              {
                // eslint-disable-next-line react/prop-types
                drupalLinks.reportViolenceAndHarassment
                // eslint-disable-next-line max-len
                && <ButtonLogo route={drupalLinks.reportViolenceAndHarassment.link} iconName="icon-document-shadow" textKey="others.home_report_violence_harassment" externalLink pictoClasses="f-blue" taggingEventOnClick={() => taggingEventOtherElement(Translate('others.home_report_violence_harassment'))} />
              }
              <ButtonLogo route={routes.VCUB.HOME.name} iconName="icon-vcub-shadow" textKey="vcub.title_home" taggingEventOnClick={() => taggingEventOtherElement(Translate('vcub.title_home'))} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OtherSection.propTypes = {
  drupalLinks: PropTypes.shape({
    phmr: PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      nid: PropTypes.string.isRequired,
    }),
    reportViolenceAndHarassment: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }),
  }).isRequired,
  drupalTerms: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  drupalLinks: state.app.drupalLinks,
  drupalTerms: state.app.drupalTerms,
});


export default connect(mapStateToProps, null)(OtherSection);
